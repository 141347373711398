unit Main;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.pngimage, WEBLib.ExtCtrls, VCL.TMSFNCCustomComponent,
  VCL.TMSFNCBitmapContainer, Vcl.Imaging.jpeg, Vcl.Menus, WEBLib.Menus,
  WEBLib.ComCtrls;
//WEBLib.ClientConnector;
//Weblib.JSON;
type
  TFMain = class(TForm)
    TMSFNCBitmapContainer1: TTMSFNCBitmapContainer;
    WebPanel1: TPanel;
    WebImageControl1: TImageControl;
    WebMainMenu1: TMainMenu;
    MFolap: TMenuItem;
    WebPanel2: TPanel;
    MCegek: TMenuItem;
    MSzolgaltatasok: TMenuItem;
    MKapcsolat: TMenuItem;
    DTNPBt1: TMenuItem;
    CsekkArchvKft1: TMenuItem;
    WebPanel4: TPanel;
    WebPanel6: TPanel;
    WebPanel7: TPanel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebImageControl3: TImageControl;
    WebImageControl4: TImageControl;
    WebImageControl5: TImageControl;
    WebPanel8: TPanel;
    WebPanel5: TPanel;
    WebPageControl1: TPageControl;
    WebPageControl1Sheet_Folap: TTabSheet;
    WebImageControl6: TImageControl;
    WebLabel2: TLabel;
    WebPageControl1Sheet_Cegek: TTabSheet;
    WebPageControl1Sheet_Szolgaltatasok: TTabSheet;
    WebPageControl1Sheet_Kapcsolat: TTabSheet;
    WebPanel3: TPanel;
    WebLabel1: TLabel;
    WebLabel10: TLabel;
    WebScrollBox1: TScrollBox;
    WebImageControl7: TImageControl;
    WebLabel17: TLabel;
    WebLabel18: TLabel;
    WebPanel10: TPanel;
    WebPanel11: TPanel;
    WebLabel22: TLabel;
    WebLabel23: TLabel;
    WebLabel24: TLabel;
    WebLabel25: TLabel;
    WebLabel26: TLabel;
    WebLabel27: TLabel;
    WebLabel28: TLabel;
    WebLabel29: TLabel;
    WebPanel12: TPanel;
    WebLabel11: TLabel;
    WebLabel30: TLabel;
    WebLabel31: TLabel;
    WebLabel32: TLabel;
    WebLabel33: TLabel;
    WebLabel34: TLabel;
    WebLabel35: TLabel;
    WebLabel36: TLabel;
    WebLabel37: TLabel;
    WebLabel12: TLabel;
    WebLabel14: TLabel;
    WebLabel13: TLabel;
    WebLabel15: TLabel;
    EditNev: TEdit;
    WebLabel16: TLabel;
    EditeMail: TEdit;
    WebLabel19: TLabel;
    EditTargy: TEdit;
    WebLabel20: TLabel;
    CBKategoria: TLookupComboBox;
    WebLabel21: TLabel;
    MemoUzenet: TMemo;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
    procedure WebLabel8DblClick(Sender: TObject);
    procedure WebLabel9DblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure MFolapClick(Sender: TObject);
    procedure MSzolgaltatasokClick(Sender: TObject);
    procedure MKapcsolatClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FMain: TFMain;

implementation

{$R *.dfm}

procedure TFMain.MFolapClick(Sender: TObject);
begin
  self.WebPageControl1Sheet_Folap.TabVisible:=True;
  self.WebPageControl1Sheet_Cegek.TabVisible:=False;
  self.WebPageControl1Sheet_Szolgaltatasok.TabVisible:=False;
  self.WebPageControl1Sheet_Kapcsolat.TabVisible:=False;
  self.WebPageControl1.TabIndex:=0;
end;

procedure TFMain.MKapcsolatClick(Sender: TObject);
begin
  self.WebPageControl1Sheet_Folap.TabVisible:=False;
  self.WebPageControl1Sheet_Cegek.TabVisible:=False;
  self.WebPageControl1Sheet_Szolgaltatasok.TabVisible:=False;
  self.WebPageControl1Sheet_Kapcsolat.TabVisible:=True;
  self.WebPageControl1.TabIndex:=3;
end;

procedure TFMain.MSzolgaltatasokClick(Sender: TObject);
begin
  self.WebPageControl1Sheet_Folap.TabVisible:=False;
  self.WebPageControl1Sheet_Cegek.TabVisible:=False;
  self.WebPageControl1Sheet_Szolgaltatasok.TabVisible:=True;
  self.WebPageControl1Sheet_Kapcsolat.TabVisible:=False;
  self.WebPageControl1.TabIndex:=2;
end;

procedure TFMain.WebButton1Click(Sender: TObject);
//var a:System.PChar;
begin
//WebLib.ClientConnector.TWebClientConnector
//  Weblib.JSON
// window.RTCPeerConnection.
//$.getJSON('http://ipinfo.io', function(data){console.log(data);});
//showmessage(Weblib.JSON.TJSON..TJSONObject.UnitScope);
(*
  //Ellenőrzések
  if self.EditNev.Text='' then begin
     showmessage('Saját nevének megadása kötelező!');
     self.EditNev.SetFocus;
     Exit;
  end;
  if self.EditeMail.Text='' then begin
     showmessage('Saját eMail címének megadása kötelező!');
     self.EditeMail.SetFocus;
     Exit;
  end;
  if self.EditTargy.Text='' then begin
     showmessage('Az üzenet tárgyának megadása kötelező!');
     self.EditTargy.SetFocus;
     Exit;
  end;
  if self.CBKategoria.DisplayText='' then begin
     showmessage('Válaszson egy Kategória/Személy csoportot!');
     self.CBKategoria.SetFocus;
     Exit;
  end;
  if self.MemoUzenet.Text='' then begin
     showmessage('Nem adott meg üzenetet!');
     self.MemoUzenet.SetFocus;
     Exit;
  end;
  if True then



  //Üzenetküldés

Email.send({
    Gazdagép: "smtp.elasticemail.com",
    Felhasználónév: "felhasználónév",
    Jelszó: "jelszó",
    Címzett: 'ők@webhely.com',
    Feladó: "you@isp.com",
    Tárgy: "Ez a téma",
    Test: "És ez a test"
}).azután(
  üzenet => figyelmeztetés (üzenet)
);
*)

(*
asm
   Email.send({
      SecureToken : "300889c4-xxxxxxxxxxx-1faxxxc63843",
      To : 'hflick@me.com',
      From : "newsletter@app.flixengineering.com",
      Subject : "This is the subject",
      Body : "And this is the body"
   }).then(
   message => alert(message)
  );
  end;
*)

(*
  asm
  Email.send({
    Host : "mail.t-online.hu",
    Username : "drexler",
    Password : "Tibor1963",
    To : "info@dtnp.hu",
    From : "drexler@dtnp.hu",
    Subject : "Próba tárgy",
    Body : "Próba szöveg"
  }).then(
  message => alert("Hiba")
  );
  end;
*)



end;

procedure TFMain.WebFormCreate(Sender: TObject);
begin
  self.WebPageControl1Sheet_Folap.TabVisible:=True;
  self.WebPageControl1Sheet_Cegek.TabVisible:=False;
  self.WebPageControl1Sheet_Szolgaltatasok.TabVisible:=False;
  self.WebPageControl1Sheet_Kapcsolat.TabVisible:=False;
  self.WebPageControl1.TabIndex:=0;
  self.MemoUzenet.Text:='';

end;

procedure TFMain.WebLabel8DblClick(Sender: TObject);
begin
  window.location.href := 'mailto:info@csekk-archiv.hu';
end;

procedure TFMain.WebLabel9DblClick(Sender: TObject);
begin
  window.location.href := 'mailto:info@dtnp.hu';
end;

procedure TFMain.LoadDFMValues;
begin
  inherited LoadDFMValues;

  TMSFNCBitmapContainer1 := TTMSFNCBitmapContainer.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl7 := TImageControl.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebPanel6 := TPanel.Create(Self);
  WebPanel7 := TPanel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebImageControl3 := TImageControl.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);
  WebImageControl5 := TImageControl.Create(Self);
  WebPanel8 := TPanel.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet_Folap := TTabSheet.Create(Self);
  WebImageControl6 := TImageControl.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebPageControl1Sheet_Cegek := TTabSheet.Create(Self);
  WebPageControl1Sheet_Szolgaltatasok := TTabSheet.Create(Self);
  WebPageControl1Sheet_Kapcsolat := TTabSheet.Create(Self);
  WebScrollBox1 := TScrollBox.Create(Self);
  WebPanel10 := TPanel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  EditNev := TEdit.Create(Self);
  EditeMail := TEdit.Create(Self);
  EditTargy := TEdit.Create(Self);
  CBKategoria := TLookupComboBox.Create(Self);
  MemoUzenet := TMemo.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebPanel11 := TPanel.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebLabel28 := TLabel.Create(Self);
  WebLabel29 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebLabel31 := TLabel.Create(Self);
  WebLabel32 := TLabel.Create(Self);
  WebLabel33 := TLabel.Create(Self);
  WebLabel34 := TLabel.Create(Self);
  WebLabel35 := TLabel.Create(Self);
  WebLabel36 := TLabel.Create(Self);
  WebLabel37 := TLabel.Create(Self);
  WebPanel12 := TPanel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebMainMenu1 := TMainMenu.Create(Self);
  MFolap := TMenuItem.Create(Self);
  MCegek := TMenuItem.Create(Self);
  DTNPBt1 := TMenuItem.Create(Self);
  CsekkArchvKft1 := TMenuItem.Create(Self);
  MSzolgaltatasok := TMenuItem.Create(Self);
  MKapcsolat := TMenuItem.Create(Self);

  TMSFNCBitmapContainer1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl7.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  WebPanel7.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebImageControl3.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  WebImageControl5.BeforeLoadDFMValues;
  WebPanel8.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet_Folap.BeforeLoadDFMValues;
  WebImageControl6.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebPageControl1Sheet_Cegek.BeforeLoadDFMValues;
  WebPageControl1Sheet_Szolgaltatasok.BeforeLoadDFMValues;
  WebPageControl1Sheet_Kapcsolat.BeforeLoadDFMValues;
  WebScrollBox1.BeforeLoadDFMValues;
  WebPanel10.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  EditNev.BeforeLoadDFMValues;
  EditeMail.BeforeLoadDFMValues;
  EditTargy.BeforeLoadDFMValues;
  CBKategoria.BeforeLoadDFMValues;
  MemoUzenet.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebPanel11.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebLabel28.BeforeLoadDFMValues;
  WebLabel29.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebLabel31.BeforeLoadDFMValues;
  WebLabel32.BeforeLoadDFMValues;
  WebLabel33.BeforeLoadDFMValues;
  WebLabel34.BeforeLoadDFMValues;
  WebLabel35.BeforeLoadDFMValues;
  WebLabel36.BeforeLoadDFMValues;
  WebLabel37.BeforeLoadDFMValues;
  WebPanel12.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebMainMenu1.BeforeLoadDFMValues;
  MFolap.BeforeLoadDFMValues;
  MCegek.BeforeLoadDFMValues;
  DTNPBt1.BeforeLoadDFMValues;
  CsekkArchvKft1.BeforeLoadDFMValues;
  MSzolgaltatasok.BeforeLoadDFMValues;
  MKapcsolat.BeforeLoadDFMValues;
  try
    Name := 'FMain';
    Width := 640;
    Height := 1500;
    Color := clBtnFace;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    Menu := WebMainMenu1;
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    TMSFNCBitmapContainer1.SetParentComponent(Self);
    TMSFNCBitmapContainer1.Name := 'TMSFNCBitmapContainer1';
    TMSFNCBitmapContainer1.Left := 128;
    TMSFNCBitmapContainer1.Top := 347;
    TMSFNCBitmapContainer1.Width := 26;
    TMSFNCBitmapContainer1.Height := 26;
    TMSFNCBitmapContainer1.Visible := True;
    TMSFNCBitmapContainer1.Items.Clear;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('Main.TMSFNCBitmapContainer1.Items.Bitmap.png');
      Name := 'Konyveles.png';
      Tag := 0;
    end;
    with TMSFNCBitmapContainer1.Items.Add do
    begin
      Bitmap.LoadFromFile('Main.TMSFNCBitmapContainer1.Items.Bitmap.jpg');
      Name := 'Informatika.jpg';
      Tag := 0;
    end;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 640;
    WebPanel1.Height := 60;
    WebPanel1.Align := alTop;
    WebPanel1.ChildOrder := 3;
    WebImageControl1.SetParentComponent(WebPanel1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 580;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 60;
    WebImageControl1.Height := 60;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 60.000000000000000000;
    WebImageControl1.Align := alRight;
    WebImageControl1.ChildOrder := 3;
    WebImageControl1.Picture.LoadFromFile('Main.WebPanel1.WebImageControl1.Picture.jpg');
    WebImageControl7.SetParentComponent(WebPanel1);
    WebImageControl7.Name := 'WebImageControl7';
    WebImageControl7.Left := 0;
    WebImageControl7.Top := 0;
    WebImageControl7.Width := 60;
    WebImageControl7.Height := 60;
    WebImageControl7.HeightPercent := 100.000000000000000000;
    WebImageControl7.WidthPercent := 60.000000000000000000;
    WebImageControl7.Align := alLeft;
    WebImageControl7.ChildOrder := 3;
    WebImageControl7.Picture.LoadFromFile('Main.WebPanel1.WebImageControl7.Picture.png');
    WebLabel17.SetParentComponent(WebPanel1);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.AlignWithMargins := True;
    WebLabel17.Left := 70;
    WebLabel17.Top := 10;
    WebLabel17.Width := 175;
    WebLabel17.Height := 40;
    WebLabel17.Margins.Left := 10;
    WebLabel17.Margins.Top := 10;
    WebLabel17.Margins.Right := 10;
    WebLabel17.Margins.Bottom := 10;
    WebLabel17.Align := alLeft;
    WebLabel17.AutoSize := False;
    WebLabel17.Caption := 'Csekk-Arch'#237'v Kft.   ';
    WebLabel17.Font.Charset := DEFAULT_CHARSET;
    WebLabel17.Font.Color := clWindowText;
    WebLabel17.Font.Height := -29;
    WebLabel17.Font.Name := 'Agency FB';
    WebLabel17.Font.Style := [fsBold];
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.Layout := tlCenter;
    WebLabel17.ParentFont := False;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(WebPanel1);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.AlignWithMargins := True;
    WebLabel18.Left := 462;
    WebLabel18.Top := 10;
    WebLabel18.Width := 108;
    WebLabel18.Height := 40;
    WebLabel18.Margins.Left := 10;
    WebLabel18.Margins.Top := 10;
    WebLabel18.Margins.Right := 10;
    WebLabel18.Margins.Bottom := 10;
    WebLabel18.Align := alRight;
    WebLabel18.Alignment := taRightJustify;
    WebLabel18.Caption := 'DT&&NP Bt.   ';
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clWindowText;
    WebLabel18.Font.Height := -29;
    WebLabel18.Font.Name := 'Agency FB';
    WebLabel18.Font.Style := [fsBold];
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.Layout := tlCenter;
    WebLabel18.ParentFont := False;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 60;
    WebPanel2.Width := 640;
    WebPanel2.Height := 40;
    WebPanel2.Align := alTop;
    WebPanel2.ChildOrder := 3;
    WebPanel2.Color := cl3DDkShadow;
    WebPanel2.Font.Charset := DEFAULT_CHARSET;
    WebPanel2.Font.Color := clWhite;
    WebPanel2.Font.Height := -13;
    WebPanel2.Font.Name := 'Tahoma';
    WebPanel2.Font.Style := [fsBold];
    WebPanel2.ParentFont := False;
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 100;
    WebPanel4.Width := 640;
    WebPanel4.Height := 30;
    WebPanel4.Align := alTop;
    WebPanel4.ChildOrder := 5;
    WebPanel6.SetParentComponent(Self);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 0;
    WebPanel6.Top := 130;
    WebPanel6.Width := 640;
    WebPanel6.Height := 1350;
    WebPanel6.Align := alClient;
    WebPanel6.ChildOrder := 6;
    WebPanel7.SetParentComponent(WebPanel6);
    WebPanel7.Name := 'WebPanel7';
    WebPanel7.Left := 0;
    WebPanel7.Top := 0;
    WebPanel7.Width := 201;
    WebPanel7.Height := 1350;
    WebPanel7.Align := alLeft;
    WebPanel7.ChildOrder := 1;
    WebLabel3.SetParentComponent(WebPanel7);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 27;
    WebLabel3.Top := 20;
    WebLabel3.Width := 103;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'El'#233'rhet'#337's'#233'geink';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(WebPanel7);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 10;
    WebLabel4.Top := 80;
    WebLabel4.Width := 213;
    WebLabel4.Height := 16;
    WebLabel4.Caption := #176' 2621. Ver'#337'ce, Kod'#225'ly Zolt'#225' utca 16.';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPanel7);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 10;
    WebLabel5.Top := 130;
    WebLabel5.Width := 159;
    WebLabel5.Height := 16;
    WebLabel5.Caption := #176' Telefon: (+36 27)581-080';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(WebPanel7);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 10;
    WebLabel6.Top := 160;
    WebLabel6.Width := 152;
    WebLabel6.Height := 16;
    WebLabel6.Caption := #176' Mobil: (+36 20)941 7535';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(WebPanel7);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 10;
    WebLabel7.Top := 190;
    WebLabel7.Width := 152;
    WebLabel7.Height := 16;
    WebLabel7.Caption := #176' Mobil: (+36 20)347 8611';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(WebPanel7);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 10;
    WebLabel8.Top := 250;
    WebLabel8.Width := 132;
    WebLabel8.Height := 16;
    WebLabel8.Caption := #176' Info@csekk-archiv.hu';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel8, Self, 'OnDblClick', 'WebLabel8DblClick');
    WebLabel9.SetParentComponent(WebPanel7);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 10;
    WebLabel9.Top := 280;
    WebLabel9.Width := 87;
    WebLabel9.Height := 16;
    WebLabel9.Caption := #176' Info@dtnp.hu';
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel9, Self, 'OnDblClick', 'WebLabel9DblClick');
    WebImageControl3.SetParentComponent(WebPanel7);
    WebImageControl3.Name := 'WebImageControl3';
    WebImageControl3.Left := 10;
    WebImageControl3.Top := 50;
    WebImageControl3.Width := 25;
    WebImageControl3.Height := 25;
    WebImageControl3.HeightPercent := 100.000000000000000000;
    WebImageControl3.WidthPercent := 100.000000000000000000;
    WebImageControl3.ChildOrder := 7;
    WebImageControl3.Picture.LoadFromFile('Main.WebPanel7.WebImageControl3.Picture.png');
    WebImageControl4.SetParentComponent(WebPanel7);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 10;
    WebImageControl4.Top := 100;
    WebImageControl4.Width := 25;
    WebImageControl4.Height := 25;
    WebImageControl4.HeightPercent := 100.000000000000000000;
    WebImageControl4.WidthPercent := 100.000000000000000000;
    WebImageControl4.ChildOrder := 7;
    WebImageControl4.Picture.LoadFromFile('Main.WebPanel7.WebImageControl4.Picture.png');
    WebImageControl5.SetParentComponent(WebPanel7);
    WebImageControl5.Name := 'WebImageControl5';
    WebImageControl5.Left := 10;
    WebImageControl5.Top := 219;
    WebImageControl5.Width := 25;
    WebImageControl5.Height := 25;
    WebImageControl5.HeightPercent := 100.000000000000000000;
    WebImageControl5.WidthPercent := 100.000000000000000000;
    WebImageControl5.ChildOrder := 7;
    WebImageControl5.Picture.LoadFromFile('Main.WebPanel7.WebImageControl5.Picture.png');
    WebPanel8.SetParentComponent(WebPanel6);
    WebPanel8.Name := 'WebPanel8';
    WebPanel8.Left := 610;
    WebPanel8.Top := 0;
    WebPanel8.Width := 30;
    WebPanel8.Height := 1350;
    WebPanel8.Align := alRight;
    WebPanel8.ChildOrder := 4;
    WebPanel5.SetParentComponent(WebPanel6);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 201;
    WebPanel5.Top := 0;
    WebPanel5.Width := 409;
    WebPanel5.Height := 1350;
    WebPanel5.Align := alClient;
    WebPanel5.ChildOrder := 4;
    WebPageControl1.SetParentComponent(WebPanel5);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 0;
    WebPageControl1.Top := 0;
    WebPageControl1.Width := 409;
    WebPageControl1.Height := 1320;
    WebPageControl1.Margins.Left := 20;
    WebPageControl1.Margins.Top := 20;
    WebPageControl1.Margins.Right := 20;
    WebPageControl1.Margins.Bottom := 20;
    WebPageControl1.Align := alClient;
    WebPageControl1.ChildOrder := 4;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.ShowTabs := False;
    WebPageControl1.TabOrder := 0;
    WebPageControl1Sheet_Folap.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet_Folap.Name := 'WebPageControl1Sheet_Folap';
    WebPageControl1Sheet_Folap.Left := 0;
    WebPageControl1Sheet_Folap.Top := 20;
    WebPageControl1Sheet_Folap.Width := 409;
    WebPageControl1Sheet_Folap.Height := 1300;
    WebPageControl1Sheet_Folap.Caption := 'F'#337'lap';
    WebPageControl1Sheet_Folap.ChildOrder := 3;
    WebImageControl6.SetParentComponent(WebPageControl1Sheet_Folap);
    WebImageControl6.Name := 'WebImageControl6';
    WebImageControl6.Left := 10;
    WebImageControl6.Top := 128;
    WebImageControl6.Width := 147;
    WebImageControl6.Height := 89;
    WebImageControl6.HeightPercent := 100.000000000000000000;
    WebImageControl6.WidthPercent := 100.000000000000000000;
    WebImageControl6.ChildOrder := 2;
    WebImageControl6.Picture.LoadFromFile('Main.WebPageControl1Sheet_Folap.WebImageControl6.Picture.jpg');
    WebLabel2.SetParentComponent(WebPageControl1Sheet_Folap);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.AlignWithMargins := True;
    WebLabel2.Left := 10;
    WebLabel2.Top := 30;
    WebLabel2.Width := 389;
    WebLabel2.Height := 69;
    WebLabel2.Margins.Left := 10;
    WebLabel2.Margins.Top := 30;
    WebLabel2.Margins.Right := 10;
    WebLabel2.Align := alTop;
    WebLabel2.Caption := #220'dv'#246'z'#246'lj'#252'k a Csekk-Arch'#237'v Kft '#233's a DT&&NP Bt.  csoport k'#246'z'#246's '#252'zemeltet'#233's'#369' oldal'#225'n!';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [fsBold];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WordWrap := True;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(WebPageControl1Sheet_Folap);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.AlignWithMargins := True;
    WebLabel10.Left := 10;
    WebLabel10.Top := 222;
    WebLabel10.Width := 350;
    WebLabel10.Height := 38;
    WebLabel10.Margins.Left := 10;
    WebLabel10.Margins.Right := 10;
    WebLabel10.Margins.Bottom := 10;
    WebLabel10.Caption := 'K'#246'nyvel'#337't, b'#233'rsz'#225'mfejt'#337't keres, v'#225'lasszon benn'#252'nket!';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := 2763429;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Tahoma';
    WebLabel10.Font.Style := [fsBold];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.Layout := tlBottom;
    WebLabel10.ParentFont := False;
    WebLabel10.WordWrap := True;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet_Cegek.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet_Cegek.Name := 'WebPageControl1Sheet_Cegek';
    WebPageControl1Sheet_Cegek.Left := 0;
    WebPageControl1Sheet_Cegek.Top := 20;
    WebPageControl1Sheet_Cegek.Width := 409;
    WebPageControl1Sheet_Cegek.Height := 1300;
    WebPageControl1Sheet_Cegek.Caption := 'C'#233'gek';
    WebPageControl1Sheet_Cegek.ChildOrder := 1;
    WebPageControl1Sheet_Szolgaltatasok.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet_Szolgaltatasok.Name := 'WebPageControl1Sheet_Szolgaltatasok';
    WebPageControl1Sheet_Szolgaltatasok.Left := 0;
    WebPageControl1Sheet_Szolgaltatasok.Top := 20;
    WebPageControl1Sheet_Szolgaltatasok.Width := 409;
    WebPageControl1Sheet_Szolgaltatasok.Height := 1300;
    WebPageControl1Sheet_Szolgaltatasok.Caption := 'Szolg'#225'ltat'#225'sok';
    WebPageControl1Sheet_Szolgaltatasok.ChildOrder := 2;
    WebPageControl1Sheet_Kapcsolat.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet_Kapcsolat.Name := 'WebPageControl1Sheet_Kapcsolat';
    WebPageControl1Sheet_Kapcsolat.Left := 0;
    WebPageControl1Sheet_Kapcsolat.Top := 20;
    WebPageControl1Sheet_Kapcsolat.Width := 409;
    WebPageControl1Sheet_Kapcsolat.Height := 1300;
    WebPageControl1Sheet_Kapcsolat.Caption := 'Kapcsolat';
    WebPageControl1Sheet_Kapcsolat.ChildOrder := 3;
    WebScrollBox1.SetParentComponent(WebPageControl1Sheet_Kapcsolat);
    WebScrollBox1.Name := 'WebScrollBox1';
    WebScrollBox1.Left := 0;
    WebScrollBox1.Top := 0;
    WebScrollBox1.Width := 409;
    WebScrollBox1.Height := 1300;
    WebScrollBox1.Align := alClient;
    WebScrollBox1.BorderStyle := bsSingle;
    WebScrollBox1.Color := clWhite;
    WebPanel10.SetParentComponent(WebScrollBox1);
    WebPanel10.Name := 'WebPanel10';
    WebPanel10.Left := 0;
    WebPanel10.Top := 475;
    WebPanel10.Width := 409;
    WebPanel10.Height := 542;
    WebPanel10.Align := alTop;
    WebPanel10.ChildOrder := 9;
    WebPanel10.Color := clWhite;
    WebLabel13.SetParentComponent(WebPanel10);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 10;
    WebLabel13.Top := 10;
    WebLabel13.Width := 141;
    WebLabel13.Height := 19;
    WebLabel13.Caption := 'Kapcsolatfelv'#233'tel';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -16;
    WebLabel13.Font.Name := 'Tahoma';
    WebLabel13.Font.Style := [fsBold];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPanel10);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 20;
    WebLabel15.Top := 40;
    WebLabel15.Width := 292;
    WebLabel15.Height := 16;
    WebLabel15.Caption := 'Saj'#225't n'#233'v *';
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.HTML := 'Saj'#225't n'#233'v <font color="maroon"<b>*</b></font>';
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(WebPanel10);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 20;
    WebLabel16.Top := 100;
    WebLabel16.Width := 302;
    WebLabel16.Height := 16;
    WebLabel16.Caption := 'Saj'#225't eMail*';
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.HTML := 'Saj'#225't eMail <font color="maroon"<b>*</b></font>';
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel19.SetParentComponent(WebPanel10);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 20;
    WebLabel19.Top := 160;
    WebLabel19.Width := 271;
    WebLabel19.Height := 16;
    WebLabel19.Caption := 'T'#225'rgy *';
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.HTML := 'T'#225'rgy <font color="maroon"<b>*</b></font>';
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebLabel20.SetParentComponent(WebPanel10);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 20;
    WebLabel20.Top := 220;
    WebLabel20.Width := 340;
    WebLabel20.Height := 16;
    WebLabel20.Caption := 'Keteg'#243'ria/C'#237'mzett *';
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.HTML := 'Kateg'#243'ria/C'#237'mzett <font color="maroon"<b>*</b></font>';
    WebLabel20.WidthPercent := 100.000000000000000000;
    WebLabel21.SetParentComponent(WebPanel10);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 20;
    WebLabel21.Top := 280;
    WebLabel21.Width := 277;
    WebLabel21.Height := 16;
    WebLabel21.Caption := #220'zenet *';
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.HTML := #220'zenet <font color="maroon"<b>*</b></font>';
    WebLabel21.WidthPercent := 100.000000000000000000;
    EditNev.SetParentComponent(WebPanel10);
    EditNev.Name := 'EditNev';
    EditNev.Left := 20;
    EditNev.Top := 60;
    EditNev.Width := 350;
    EditNev.Height := 22;
    EditNev.ChildOrder := 2;
    EditNev.HeightPercent := 100.000000000000000000;
    EditNev.WidthPercent := 100.000000000000000000;
    EditeMail.SetParentComponent(WebPanel10);
    EditeMail.Name := 'EditeMail';
    EditeMail.Left := 20;
    EditeMail.Top := 120;
    EditeMail.Width := 350;
    EditeMail.Height := 22;
    EditeMail.ChildOrder := 2;
    EditeMail.HeightPercent := 100.000000000000000000;
    EditeMail.WidthPercent := 100.000000000000000000;
    EditTargy.SetParentComponent(WebPanel10);
    EditTargy.Name := 'EditTargy';
    EditTargy.Left := 20;
    EditTargy.Top := 180;
    EditTargy.Width := 350;
    EditTargy.Height := 22;
    EditTargy.ChildOrder := 2;
    EditTargy.HeightPercent := 100.000000000000000000;
    EditTargy.WidthPercent := 100.000000000000000000;
    CBKategoria.SetParentComponent(WebPanel10);
    CBKategoria.Name := 'CBKategoria';
    CBKategoria.Left := 20;
    CBKategoria.Top := 240;
    CBKategoria.Width := 349;
    CBKategoria.Height := 22;
    CBKategoria.HeightPercent := 100.000000000000000000;
    CBKategoria.WidthPercent := 100.000000000000000000;
    CBKategoria.ItemIndex := -1;
    CBKategoria.LookupValues.Clear;
    with CBKategoria.LookupValues.Add do
    begin
      Value := '0';
      DisplayText := 'K'#246'nyvel'#233's, b'#233'rsz'#225'mfejt'#233's, ad'#243'z'#225's -Drexlern'#233' Peth'#337' Kl'#225'ra';
    end;
    with CBKategoria.LookupValues.Add do
    begin
      Value := '1';
      DisplayText := 'K'#246'nyvel'#233's, b'#233'rsz'#225'mfejt'#233's, ad'#243'z'#225's -Drexler Tibor';
    end;
    with CBKategoria.LookupValues.Add do
    begin
      Value := '2';
      DisplayText := 'Informatika -Drexler Tibor';
    end;
    MemoUzenet.SetParentComponent(WebPanel10);
    MemoUzenet.Name := 'MemoUzenet';
    MemoUzenet.Left := 20;
    MemoUzenet.Top := 300;
    MemoUzenet.Width := 349;
    MemoUzenet.Height := 189;
    MemoUzenet.AutoSize := False;
    MemoUzenet.HeightPercent := 100.000000000000000000;
    MemoUzenet.Lines.BeginUpdate;
    try
      MemoUzenet.Lines.Clear;
      MemoUzenet.Lines.Add('MemoUzenet');
    finally
      MemoUzenet.Lines.EndUpdate;
    end;
    MemoUzenet.SelLength := 0;
    MemoUzenet.SelStart := 0;
    MemoUzenet.WantTabs := True;
    MemoUzenet.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(WebPanel10);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 20;
    WebButton1.Top := 495;
    WebButton1.Width := 111;
    WebButton1.Height := 25;
    WebButton1.Caption := #220'zenet k'#252'ld'#233'se';
    WebButton1.ChildOrder := 11;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebPanel11.SetParentComponent(WebScrollBox1);
    WebPanel11.Name := 'WebPanel11';
    WebPanel11.Left := 0;
    WebPanel11.Top := 0;
    WebPanel11.Width := 409;
    WebPanel11.Height := 409;
    WebPanel11.Align := alTop;
    WebPanel11.ChildOrder := 9;
    WebPanel11.Color := clWhite;
    WebLabel22.SetParentComponent(WebPanel11);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 30;
    WebLabel22.Top := 50;
    WebLabel22.Width := 83;
    WebLabel22.Height := 16;
    WebLabel22.Caption := 'Drexler Tibor';
    WebLabel22.Font.Charset := DEFAULT_CHARSET;
    WebLabel22.Font.Color := clWindowText;
    WebLabel22.Font.Height := -13;
    WebLabel22.Font.Name := 'Tahoma';
    WebLabel22.Font.Style := [fsBold,fsItalic];
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.ParentFont := False;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebLabel23.SetParentComponent(WebPanel11);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 30;
    WebLabel23.Top := 70;
    WebLabel23.Width := 131;
    WebLabel23.Height := 16;
    WebLabel23.Caption := 'DT&&NP Bt. '#252'gyvezet'#337'je';
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebLabel24.SetParentComponent(WebPanel11);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 30;
    WebLabel24.Top := 90;
    WebLabel24.Width := 444;
    WebLabel24.Height := 16;
    WebLabel24.Caption := 'Informatikai, sz'#225'mviteli (k'#246'nyvel'#337'i), b'#233'rsz'#225'mfejt'#233'se, ad'#243'tan'#225'csad'#243'i feladatok.';
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebLabel25.SetParentComponent(WebPanel11);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 30;
    WebLabel25.Top := 120;
    WebLabel25.Width := 45;
    WebLabel25.Height := 16;
    WebLabel25.Caption := #176' Mobil:';
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.WidthPercent := 100.000000000000000000;
    WebLabel26.SetParentComponent(WebPanel11);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 50;
    WebLabel26.Top := 140;
    WebLabel26.Width := 103;
    WebLabel26.Height := 16;
    WebLabel26.Caption := '(+36 20)941 7535';
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebLabel27.SetParentComponent(WebPanel11);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 30;
    WebLabel27.Top := 160;
    WebLabel27.Width := 38;
    WebLabel27.Height := 16;
    WebLabel27.Caption := #176' Mail:';
    WebLabel27.HeightPercent := 100.000000000000000000;
    WebLabel27.WidthPercent := 100.000000000000000000;
    WebLabel28.SetParentComponent(WebPanel11);
    WebLabel28.Name := 'WebLabel28';
    WebLabel28.Left := 50;
    WebLabel28.Top := 180;
    WebLabel28.Width := 140;
    WebLabel28.Height := 16;
    WebLabel28.Caption := 'drexler@csekk-archiv.hu';
    WebLabel28.HeightPercent := 100.000000000000000000;
    WebLabel28.WidthPercent := 100.000000000000000000;
    WebLabel29.SetParentComponent(WebPanel11);
    WebLabel29.Name := 'WebLabel29';
    WebLabel29.Left := 50;
    WebLabel29.Top := 200;
    WebLabel29.Width := 95;
    WebLabel29.Height := 16;
    WebLabel29.Caption := 'drexler@dtnp.hu';
    WebLabel29.HeightPercent := 100.000000000000000000;
    WebLabel29.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(WebPanel11);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 10;
    WebLabel11.Top := 20;
    WebLabel11.Width := 121;
    WebLabel11.Height := 19;
    WebLabel11.Caption := 'Munkat'#225'rsaink';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Tahoma';
    WebLabel11.Font.Style := [fsBold];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel30.SetParentComponent(WebPanel11);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 30;
    WebLabel30.Top := 230;
    WebLabel30.Width := 146;
    WebLabel30.Height := 16;
    WebLabel30.Caption := 'Drexlern'#233'  Peth'#337' Kl'#225'ra';
    WebLabel30.Font.Charset := DEFAULT_CHARSET;
    WebLabel30.Font.Color := clWindowText;
    WebLabel30.Font.Height := -13;
    WebLabel30.Font.Name := 'Tahoma';
    WebLabel30.Font.Style := [fsBold,fsItalic];
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.ParentFont := False;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebLabel31.SetParentComponent(WebPanel11);
    WebLabel31.Name := 'WebLabel31';
    WebLabel31.Left := 30;
    WebLabel31.Top := 250;
    WebLabel31.Width := 168;
    WebLabel31.Height := 16;
    WebLabel31.Caption := 'Csekk-Arch'#237'v Kft. '#252'gyvezet'#337'je';
    WebLabel31.HeightPercent := 100.000000000000000000;
    WebLabel31.WidthPercent := 100.000000000000000000;
    WebLabel32.SetParentComponent(WebPanel11);
    WebLabel32.Name := 'WebLabel32';
    WebLabel32.Left := 30;
    WebLabel32.Top := 270;
    WebLabel32.Width := 370;
    WebLabel32.Height := 16;
    WebLabel32.Caption := 'Sz'#225'mviteli (k'#246'nyvel'#337'i), b'#233'rsz'#225'mfejt'#233'se, ad'#243'tan'#225'csad'#243'i feladatok.';
    WebLabel32.HeightPercent := 100.000000000000000000;
    WebLabel32.WidthPercent := 100.000000000000000000;
    WebLabel33.SetParentComponent(WebPanel11);
    WebLabel33.Name := 'WebLabel33';
    WebLabel33.Left := 30;
    WebLabel33.Top := 300;
    WebLabel33.Width := 45;
    WebLabel33.Height := 16;
    WebLabel33.Caption := #176' Mobil:';
    WebLabel33.HeightPercent := 100.000000000000000000;
    WebLabel33.WidthPercent := 100.000000000000000000;
    WebLabel34.SetParentComponent(WebPanel11);
    WebLabel34.Name := 'WebLabel34';
    WebLabel34.Left := 50;
    WebLabel34.Top := 320;
    WebLabel34.Width := 103;
    WebLabel34.Height := 16;
    WebLabel34.Caption := '(+36 20)941 7535';
    WebLabel34.HeightPercent := 100.000000000000000000;
    WebLabel34.WidthPercent := 100.000000000000000000;
    WebLabel35.SetParentComponent(WebPanel11);
    WebLabel35.Name := 'WebLabel35';
    WebLabel35.Left := 30;
    WebLabel35.Top := 340;
    WebLabel35.Width := 38;
    WebLabel35.Height := 16;
    WebLabel35.Caption := #176' Mail:';
    WebLabel35.HeightPercent := 100.000000000000000000;
    WebLabel35.WidthPercent := 100.000000000000000000;
    WebLabel36.SetParentComponent(WebPanel11);
    WebLabel36.Name := 'WebLabel36';
    WebLabel36.Left := 50;
    WebLabel36.Top := 360;
    WebLabel36.Width := 140;
    WebLabel36.Height := 16;
    WebLabel36.Caption := 'drexler@csekk-archiv.hu';
    WebLabel36.HeightPercent := 100.000000000000000000;
    WebLabel36.WidthPercent := 100.000000000000000000;
    WebLabel37.SetParentComponent(WebPanel11);
    WebLabel37.Name := 'WebLabel37';
    WebLabel37.Left := 50;
    WebLabel37.Top := 380;
    WebLabel37.Width := 95;
    WebLabel37.Height := 16;
    WebLabel37.Caption := 'drexler@dtnp.hu';
    WebLabel37.HeightPercent := 100.000000000000000000;
    WebLabel37.WidthPercent := 100.000000000000000000;
    WebPanel12.SetParentComponent(WebScrollBox1);
    WebPanel12.Name := 'WebPanel12';
    WebPanel12.Left := 0;
    WebPanel12.Top := 409;
    WebPanel12.Width := 409;
    WebPanel12.Height := 66;
    WebPanel12.Align := alTop;
    WebPanel12.ChildOrder := 9;
    WebPanel12.Color := clWhite;
    WebLabel12.SetParentComponent(WebPanel12);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 10;
    WebLabel12.Top := 10;
    WebLabel12.Width := 107;
    WebLabel12.Height := 19;
    WebLabel12.Caption := 'Nyitvatart'#225's:';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -16;
    WebLabel12.Font.Name := 'Tahoma';
    WebLabel12.Font.Style := [fsBold];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(WebPanel12);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 30;
    WebLabel14.Top := 40;
    WebLabel14.Width := 149;
    WebLabel14.Height := 16;
    WebLabel14.Caption := #176' H'#233'tf'#337'-P'#233'ntek 8.00-16.00';
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebPanel3.SetParentComponent(WebPanel5);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 1320;
    WebPanel3.Width := 409;
    WebPanel3.Height := 30;
    WebPanel3.Align := alBottom;
    WebPanel3.ChildOrder := 4;
    WebLabel1.SetParentComponent(WebPanel3);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.AlignWithMargins := True;
    WebLabel1.Left := 3;
    WebLabel1.Top := 3;
    WebLabel1.Width := 403;
    WebLabel1.Height := 24;
    WebLabel1.Align := alClient;
    WebLabel1.Alignment := taRightJustify;
    WebLabel1.Caption := 'copyRight 2023-';
    WebLabel1.Color := clBtnFace;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.Layout := tlCenter;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebMainMenu1.SetParentComponent(Self);
    WebMainMenu1.Name := 'WebMainMenu1';
    WebMainMenu1.AutoMerge := True;
    WebMainMenu1.Appearance.BackgroundColor := cl3DDkShadow;
    WebMainMenu1.Appearance.HamburgerMenu.Caption := 'Menu';
    WebMainMenu1.Appearance.HamburgerMenu.CaptionColor := clMaroon;
    WebMainMenu1.Appearance.HamburgerMenu.BackgroundColor := clSkyBlue;
    WebMainMenu1.Appearance.HoverColor := clBackground;
    WebMainMenu1.Appearance.HoverFontColor := clHighlight;
    WebMainMenu1.Appearance.SubmenuIndicator := '&#9658;';
    WebMainMenu1.Container := WebPanel2;
    WebMainMenu1.Font.Charset := DEFAULT_CHARSET;
    WebMainMenu1.Font.Color := clWindowText;
    WebMainMenu1.Font.Height := -12;
    WebMainMenu1.Font.Name := 'Segoe UI';
    WebMainMenu1.Font.Style := [];
    WebMainMenu1.Left := 256;
    WebMainMenu1.Top := 72;
    MFolap.SetParentComponent(WebMainMenu1);
    MFolap.Name := 'MFolap';
    MFolap.Caption := 'F'#337'lap';
    SetEvent(MFolap, Self, 'OnClick', 'MFolapClick');
    MCegek.SetParentComponent(WebMainMenu1);
    MCegek.Name := 'MCegek';
    MCegek.Caption := 'C'#233'geink';
    DTNPBt1.SetParentComponent(MCegek);
    DTNPBt1.Name := 'DTNPBt1';
    DTNPBt1.Caption := 'DT&NP Bt';
    CsekkArchvKft1.SetParentComponent(MCegek);
    CsekkArchvKft1.Name := 'CsekkArchvKft1';
    CsekkArchvKft1.Caption := 'Csekk-Arch'#237'v Kft';
    MSzolgaltatasok.SetParentComponent(WebMainMenu1);
    MSzolgaltatasok.Name := 'MSzolgaltatasok';
    MSzolgaltatasok.Caption := 'Szolg'#225'ltat'#225'saink';
    SetEvent(MSzolgaltatasok, Self, 'OnClick', 'MSzolgaltatasokClick');
    MKapcsolat.SetParentComponent(WebMainMenu1);
    MKapcsolat.Name := 'MKapcsolat';
    MKapcsolat.Caption := 'Kapcsolat';
    SetEvent(MKapcsolat, Self, 'OnClick', 'MKapcsolatClick');
  finally
    TMSFNCBitmapContainer1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl7.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    WebPanel7.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebImageControl3.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
    WebImageControl5.AfterLoadDFMValues;
    WebPanel8.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet_Folap.AfterLoadDFMValues;
    WebImageControl6.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebPageControl1Sheet_Cegek.AfterLoadDFMValues;
    WebPageControl1Sheet_Szolgaltatasok.AfterLoadDFMValues;
    WebPageControl1Sheet_Kapcsolat.AfterLoadDFMValues;
    WebScrollBox1.AfterLoadDFMValues;
    WebPanel10.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    EditNev.AfterLoadDFMValues;
    EditeMail.AfterLoadDFMValues;
    EditTargy.AfterLoadDFMValues;
    CBKategoria.AfterLoadDFMValues;
    MemoUzenet.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebPanel11.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebLabel28.AfterLoadDFMValues;
    WebLabel29.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebLabel31.AfterLoadDFMValues;
    WebLabel32.AfterLoadDFMValues;
    WebLabel33.AfterLoadDFMValues;
    WebLabel34.AfterLoadDFMValues;
    WebLabel35.AfterLoadDFMValues;
    WebLabel36.AfterLoadDFMValues;
    WebLabel37.AfterLoadDFMValues;
    WebPanel12.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebMainMenu1.AfterLoadDFMValues;
    MFolap.AfterLoadDFMValues;
    MCegek.AfterLoadDFMValues;
    DTNPBt1.AfterLoadDFMValues;
    CsekkArchvKft1.AfterLoadDFMValues;
    MSzolgaltatasok.AfterLoadDFMValues;
    MKapcsolat.AfterLoadDFMValues;
  end;
end;

end.
